
:root{
    --blue:#2b5072;
    --light-blue:#2aa0ef;
    --light-blue-2:#56aee3;
    --soft-white:rgba(150, 193, 216, 0.178);
    --green:#8bac24;
}
html,body{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    scroll-behavior: smooth;
    color:#41484d;
    overflow-x:hidden;
}
h1,h2{
    font-weight: lighter;
}
.form-mail{
    display: flex;
    flex-direction: column;
    width: 40vw;
}
.form-mail > input {
    margin-bottom: 20px;
    margin-top: 5px;
    padding: 10px 10px;
    border: none;
    border: 1px solid rgba(128, 128, 128, 0.356);
    border-radius: 3px;
}
.form-mail > div > strong{
    margin-left: 5px;
    color: rgb(187, 128, 19);
}
.form-mail > input:focus{
    outline: none;
    border: 1px solid var(--light-blue);
}

.form-mail > button {
    color: #fff;
    background-color: var(--light-blue-2);
    border-radius: 5px;
    border: none;
    width: 90px;
    padding: 15px 0px;
    cursor: pointer;
    transition: background-color .3s;
    margin-top: 20px;
}

.form-mail > button:hover{
    background-color: var(--blue);
}

.header_mobile-header{
    display: none;
}

header{
    width: 100vw;
    height: 20vh;
}
.header_top-bar{
    align-items: center;
    background-color: var(--blue);
    display: flex;
    justify-content: flex-end;
    height: 25%;
    color: rgba(150, 193, 216, 0.81);   
    position: relative;
}

.header_top-bar_item {
    display: flex;
    align-items: center;
    padding-right: 20px;
    padding-left: 20px;
    border-right: var(--soft-white);
    height: 100%;
    transition: background-color .5s;
    cursor: pointer;
}

.header_top-bar_item:hover{
    background-color: var(--light-blue-2);
    color: #fff;
}

.header_top-bar_item > img {
    margin-right: 10px;
}

.header_top-bar_item_svg{
    width: 18px;
    height: 18px;
    margin-right: 10px;
}
.header_top-bar_item_svg-caret{
    width: 13px;
    height: 13px;
}
.header_top-bar_lenguages{
    position: absolute;
    top: 3.4vh;
    right: 20px;
    list-style: none;
    padding: 15px;
    width: 200px;
    z-index: 1;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    animation: slideToUpLenguage .3s ease-in;
    background-color: #fff;
}

.header_top-bar_lenguages > li {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    color: grey;
    transition: background-color .5s;
}
.header_top-bar_lenguages > li > img {
    margin-right: 10px;
}
.header_top-bar_lenguages > li:hover{
    background-color: rgb(81, 159, 211);
    color: #fff;
}
.header_nav{
    height: 75%;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    font-size: 1.3rem;
}
.header_nav_logo{
    width: 200px;
    position: absolute;
    left: 0;
    margin-left: 10vw;
    cursor: pointer;
}

.header_nav_main-menu{
    display: flex;
    list-style: none;
    position: absolute;
    right: 0;
    margin-right: 10vw;
}

.header_nav_main-menu > li {
    margin-right: 25px;
    cursor: pointer;
    transition: color .3s;
}

.header_nav_main-menu > li > a {
    text-decoration: none;
    color: inherit;
}

.header_nav_main-menu > li:hover{
    color: var(--light-blue); 
}

.header_nav_services{
    position: absolute;
    top:13vh;
    width: 90vw;
    display: flex;
    margin: auto;
    padding: 30px;
    background-image: url('./img/seowp-dropdown-bg.png');
    background-repeat: no-repeat;
    background-position: right bottom;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    animation: slideToUp .3s ease-in;
    background-color: #fff;
    z-index: 2;
}

.header_nav_services > div {
    margin-right: 20px;
    position: relative;
}

.header_nav_services > div > span {
    position: absolute;
}

.header_nav_services > div > ul {
    padding: 0;
    list-style: none;
    color: rgba(2, 11, 18, 0.63);
    margin-top: 40px;
}

.header_nav_services > div > ul > li {
    padding: 10px 30px;
    cursor: pointer;
    transition: background-color .5s;
}

.header_nav_services > div > ul > li > a{
    color: inherit;
    text-decoration: none;
}

.header_nav_services > div > ul > li:hover{
    color: #fff;
    background-color:var(--light-blue); 
}
.header_mobile-nav{
    display: none;
}
.slideToDown{
    top: 20vh;
    opacity: 0;
    animation: slideToDown .3s ease-out;
}
.slideToDownLenguages{
    animation: slideToDownLenguages .3s ease-out;
    top:5vh;
    opacity: 0;
}

@keyframes slideToUpLenguage {
    from{
        top: 5vh;
        opacity: 0;
    }
    to{
        top: 3.4vh;
        opacity: 1;
    }
}

@keyframes slideToDownLenguages {
    from{
        top:3.4vh;
        opacity: 1;
    }
    to{
        top:5vh;
        opacity: 0;
    }
}

@keyframes slideToUp {
    from {
        top: 17vh;
        opacity: 0;
    }
    to {
        top: 13vh;
        opacity: 1;
    }
}

@keyframes slideToDown {
    from{
        top:13vh;
        opacity: 1;
    }
    to{
        top:17vh;
        opacity: 0;
    }
}

/*Home*/
.main_home-video{
    width: 100vw;
    margin-bottom: 5vh;
}

.main_home-video > video {
    width: inherit;
}

.main_home-marketing{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5vh;
}

.main_home-marketing > h1 {
    margin-bottom: 5vh;
    font-weight: lighter;
}

.main_home-marketing_cards{
    display: flex;
    padding: 0vh 5vw;
    height: 350px;
}

.main_home-marketing_cards > div {
    width: 25%;
    position: relative;
    max-width: 300px;
    min-width: 180px;
    cursor: pointer;
    margin: 10px;
    transition: transform .3s ease-in;
}

.main_home-marketing_cards > div:hover{
    transform: scale(1.05);
}

.main_home-marketing_cards > div > img {
    width: 100%;
    height: 80%;
    object-fit: contain;
}

.main_home-marketing_cards > div > span {
    background-color: rgba(0, 0, 0, 0.15);
    width: 100%;
    height: 19%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.main_home-marketing_cards_marketing{
    background-color: var(--blue);
}

.main_home-marketing_cards_user{
    background-color: var(--light-blue-2);
}

.main_home-marketing_cards_brand{
    background-color: rgb(161, 198, 39);
}

.main_home-marketing_cards_mobile{
    background-color: var(--green);
}

.main_home-about-us{
    align-items: center;
    display: flex;
    background-color: var(--light-blue-2);
    padding: 0vh 15vw;
    color: #fff;
    margin-bottom: 5vh;
    height: 45vh;
}

.main_home-about-us_info {
    margin-left: 5vw;
}

.main_home-about-us_info > h1{
    font-weight: lighter;
    font-size: 4vh;
}

.main_home-about-us_info > p{
    font-size: 2.2vh;
}

.main_home-about-us > img {
    width: 25vw;
}
.main_home-other-services{
    padding: 0vh 15vw;
    text-align: center;
}

.main_home-other-services_container{
    display: flex;
    text-align: left;
    margin-bottom: 5vh;
}

.main_home-other-services_container_column{
    margin-right: 20px;
    width: 50%;
}

.main_home-other-services_container_column_service > div {
    display: flex;
    align-items: center;
}

.icon-services{
    width: 30px;
    height: 30px;
    margin-right: 10px;
    background-color: var(--green);
    border-radius: 50%;
    padding: 10px;
    color: #fff;
}

.blueIcon{
    background-color: var(--light-blue-2);
}

.contact_container{
    display: flex;
    justify-content: center;
    margin-top: 4vh;
}

.contact_container_info {
    margin-right: 5vw;
}

.contact_container_info > ul {
    list-style: none;
    padding: 0;
}
.contact_container_info > ul > div {
    margin-bottom: 20px;
}
.contact_container_info > ul > div > small{
    font-size: .9rem;
    color: rgba(128, 128, 128, 0.411);
}
.contact_container_info > ul > div > li > span{
    font-size: 1.4rem;
}
.contact_container_info > ul > li {
    display: flex;
    margin-bottom: 20px;
}
.contact_container_info > ul > li > :first-child{
    margin-right: 10px;
}
.contact_container_form > h1{
    margin-top: 0;
}

.service > p{
    padding: 8vh 10vw;
    font-size: 1.6rem;
    text-align: center;
}

.service_banner{
    height: 65vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #fff;
    background-image: url('./img/pattern-shattered-transparent-contrast-low.png');
}

.service_banner > img {
    width: 350px;
    height: 350px;
}

.service_banner > h1{
    font-size: 3rem;
    margin: 0;
}

.service_banner > p {
    font-size: 1.5rem;
}

.service_form{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(241, 241, 241);
    padding: 4vh 0vh;
}

.service_form > h2 {
    font-size: 2.8rem;
    text-align: center;
}

.service_form > form{
    align-self: flex-start;
    margin-left: 5vw;
}

/*Error 404*/
.error404_container{
    display: flex;
    justify-content: center;
    height: 30vh;
    align-items: center;
}

.error404_container > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.error404_container > div > strong {
    color: var(--light-blue-2);
    font-size: 5rem;
    font-weight: lighter;
}

.error404_container > div > span {
    font-size: 1.5rem;
}

.error404_container_icon{
    font-size: 9rem;
    color: rgba(0, 0, 0, 0.158);
    margin-right: 2vw;
}

/*Footer*/
footer{
    background-color: #25282a;
    width: 100vw;
    height: 18vh;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.footer_copyright{
    display: flex;
    align-items: center;
    color: #a1a3a4;
}

.footer_copyright > span {
    margin-left: 20px;
}

.footer_copyright_to-up {
    cursor: pointer;
    width: 25px;
    height: 25px;
    border-radius: 5px;
    padding: 10px;
    border: 1px solid rgba(136, 136, 136, 0.23);
    transition: background-color .3s;
}

.footer_copyright_to-up:hover{
    background-color: rgb(81, 159, 211);
    color: #fff;
}

@media screen and (max-width: 800px) {
    /*Header*/
    header{
        height: 70px;
        width: 100vw;
        margin-bottom:3vh;
    }
    .header_top-bar {
        display: none;
    }
    .header_nav{
        display: none;
    }
    .header_mobile-header{
        display: flex;
        align-items: center;
        position: relative;
        width: inherit;
        height: inherit;
    }
    .header_mobile-header > img {
        padding-left: 20px;
        padding-top: 20px;
        width: 140px;
    }
    .header_mobile-header_icon-menu {
        position: absolute;
        right: 30px;
        font-size: 2rem;
        color: var(--light-blue-2);
    }
    .header_mobile-nav{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40vw;
        height: 100vh;
        min-width: 200px;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
        background-color: #fff;
        animation: slideMenuToLeft .5s ease-in;
    }
    .header_mobile-nav_langs{
        position: absolute;
        right: 10px;
        top: 10px;
    }
    .header_mobile-nav_langs > *{
        width: 19px;
        height: 19px;
        margin-right: 10px;
    }
    .header_mobile-nav > ul{
        padding: 0;
        margin: 0;
        width: inherit;
        padding: 0 20px;
    }
    .header_mobile-nav > ul > li {
        list-style: none;
        margin-bottom: 4vh;
        border-bottom: 1px solid #00000028;
        padding-bottom: 10px;
    }
    .header_mobile-nav > ul > li > a {
        text-decoration: none;
        color: inherit;
    }
    .header_mobile-nav_logo{
        position: absolute;
        bottom: 10px;
        right: 10px;
        width: 120px;
    }
    .headear_close-menu{
        height: 100vh;
        width: 100vw;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.673);
        z-index: 1;
        animation: fadeIn .5s ease-in;
    }
    .slideMenuToRight {
        animation: slideMenuToRight .5s ease-out;
        right: -40vw;
    }
    .fadeOut{
        animation: fadeOut .5s ease-out;
        opacity: 0;
    }
    @keyframes fadeOut{
        from{
            opacity: 1;
        }
        to{
            opacity: 0;
        }
    }
    @keyframes fadeIn{
        from{
            opacity: 0;
        }
        to{
            opacity: 1;
        }
    }
    @keyframes slideMenuToLeft {
        from{
            right: -40vw;
        }
        to{
            right: 0vw;
        }
    }
    @keyframes slideMenuToRight {
        from{
            right:0vw;
        }
        to{
            right: -40vw;
        }
    }
    /*Home*/
    .main_home-video{
        display: none;
    }
    .main_home-marketing{
        align-items: center;;
    }
    
    .main_home-marketing_cards{
        flex-wrap: wrap;
        padding: 0;
        height: auto;
        align-items: center;
        justify-content: center;
    }
    
    .main_home-marketing_cards > div {
        width: 100%;
        margin: 5px;
    }

    .main_home-marketing_cards > div > span {
        font-size: 1.1rem;
        padding: 1vh 0px;
    }

    .main_home-about-us{
        flex-direction: column;
        padding: 0vh 5vw;
        color: #fff;
        margin-bottom: 5vh;
        height: auto;
    }
    
    .main_home-about-us_info {
        margin-left: 0;
    }
    
    .main_home-about-us_info > h1{
        text-align: center;
    }
    
    .main_home-about-us_info > p{
        font-size: 2.2vh;
        text-align: center;
    }
    
    .main_home-about-us > img {
        width: 70vw;
        max-width: 400px;
    }

    .main_home-other-services{
        padding: 0vh 5vw;
        text-align: center;
    }
    
    .main_home-other-services_container{
        flex-direction: column;
        align-items: center;
        text-align: left;
        margin-bottom: 5vh;
    }
    
    .main_home-other-services_container_column{
        margin-right: 0;
        width: 100%;
    }
    .main_home-other-services_container_column_service{
        margin-bottom: 3vh;
    }
    .main_home-other-services_container_column_service > div {
        display: flex;
        align-items: center;
    }

    .main_home-other-services_container_column_service > p {
        text-align: center;
    }

    /*Contact*/
    .contact_container{
        flex-direction: column;
        margin-top: 5vh;
        align-items: center;
    }
    
    .contact_container_info {
        margin-right: 0;
        margin-bottom: 5vh;
    }

    .form-mail{
        display: flex;
        flex-direction: column;
        width: 90vw;
    }

    /*Error 404*/
    .error404_container{
        flex-direction: column;
        height: 50vh;
    }

    .error404_container > div {
        align-items: center;
    }

    .error404_container > div > strong {
        color: var(--light-blue-2);
        font-size: 5rem;
        font-weight: lighter;
    }

    .error404_container > div > span {
        font-size: 1.5rem;
        margin-top: 2vh;
    }

    .error404_container_icon{
        font-size: 9rem;
        color: rgba(0, 0, 0, 0.158);
        margin-right: 0;
        margin-bottom: 2vh;
    }

    .service > p{
        padding: 3vh 4vw;
        font-size: 1.4rem;
        text-align: left;
    }
    
    .service_banner{
        height: 70vh;
    }
    
    .service_banner > img {
        width: 300px;
        height: 300px;
    }
    
    .service_banner > h1{
        font-size: 2.5rem;
    }
    
    .service_banner > p {
        font-size: 1.3rem;
    }
    
    .service_form{
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: rgb(241, 241, 241);
        padding: 4vh 0vh;
    }
    
    .service_form > h2 {
        font-size: 2rem;
        text-align: center;
    }
    
    .service_form > form{
        align-self: flex-start;
        margin-left: 5vw;
    }

    /*Footer*/
    footer{
        height: auto;
        align-items: center;
        flex-direction: column;
    }
    
    .footer_copyright{
        flex-direction: column;
        margin-left: 0;
        justify-content: center;
        width: 100vw;
    }
    
    .footer_copyright > span {
        margin-left: 0;
        margin-bottom: 20px;
    }
    
    .footer_copyright_to-up {
        margin: 20px;
    }
    .footer_google-partner{
        margin-bottom: 20px;
    }
    
}



